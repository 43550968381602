import React, { useState } from 'react';
import { Button, Row, Col,message, Icon, Form, Input ,Modal} from 'antd';
import { firebaseAppAuth } from "../Services/FirebaseIntegration";
import { Link, withRouter } from "react-router-dom";
import Header from '../Components/Header';

const EmailSignIn = (props) => {
    const [email,setEmail] = useState('');
    const [password,setPassword] =useState('');
    const [errorFLag,setFlag] = useState(false);
    const [errMessage,setErrMessage] = useState()
    const [succMessage,setSuccMessage] = useState('')
    const [ loader,setLoader] = useState(false);
    const handleSubmit = (e) => {
      setLoader(true)
      e.preventDefault()
      firebaseAppAuth.createUserWithEmailAndPassword(email, password)
      .then(function() {
        emailVerification()
        setErrMessage('')        
      })
      .catch(function(error) {
        setLoader(false)
        setFlag(true)
        if(error.code ==="auth/invalid-email"){
          message.error(error.message)
        }
        else if(error.code ==='auth/weak-password'){
          message.error(error.message)
        }
        if(error.code ==='auth/email-already-in-use'){
          info()
        }
      })
    }
    function info() {
      Modal.info({
        className: "info-modal-email",
        centered: true,
        footer: null,
        content: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <div>It seems that you are already signed up with this email using another method.</div>
            <div>Please Sign up with another Email.</div>
          </div>
        ),
        onOk() { },
      });
    }
    function emailVerification(){
      var user = firebaseAppAuth.currentUser;
      user.sendEmailVerification().then(function(){
        setLoader(false)
        message.success(`Verification Email sent to ${email}`)
      }).catch(error=>{
        setLoader(false)
        window.alert('Error'+ error)
      })
    }
    const handleChange = (e)=>{
        e.preventDefault();
        setPassword(e.target.value)
    }
    const handleChangeEmail = (e)=>{
        e.preventDefault();
        setEmail(e.target.value)
    }

    return (
      <div className="app">
      <div className="app-container">
        <Header
          {...props}
          header_description='Sign Up Using Email and Password'
          customClass_header_description="sign-up-header-text"
        />
            <Row style={{margin:'3em'}}>
                <Col xs={{ span: 24 }} md={{ span: 8, offset: 8 }}>
                    <Form onSubmit={handleSubmit} className="login-form">
                        <Form.Item style={{marginBottom: '1em'}}>
                            <Input
                                size="large"
                                name="email" value={email} onChange={handleChangeEmail}
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item style={{marginBottom: '1em'}}>
                            <Input name="password"   size="large" value={password} onChange={handleChange}
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item style={{marginBottom: '1em'}}>
                            <Button  block className="save-btn" size="large"  htmlType="submit" loading={loader}>Sign up</Button>
                        </Form.Item>
                     
                        <Form.Item>
                        {errorFLag ? 
                          <span style={{color:'red'}}>{errMessage}</span>:null
                        } <span style={{color:'green'}}>{succMessage}</span>
                        </Form.Item>
                       
                        <Form.Item style={{marginBottom: '1em'}}>
                            <Link to="/signin/email"><span>Back to Login</span> </Link>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
        </div>
    );
};

export default withRouter(EmailSignIn);