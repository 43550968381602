import * as firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/storage";

import firebaseConfig from '../firebaseConfig';

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAppAuth = firebaseApp.auth();
export const storage = firebase.storage()
export const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    facebookProvider: new firebase.auth.FacebookAuthProvider(),
};