import * as CitiesActions from '../ActionTypes/ActionTypes';
const inititalState = {
}

export default function cities(state=inititalState, action) {
    switch (action.type) {
        case CitiesActions.REQUSET_CITES:
            return { ...state };

        case CitiesActions.RECIEVED_CITES:
            return action.city;
            
        default:
            return state
    }
}