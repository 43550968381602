import React, { Suspense, Component } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import './Custom.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { routes } from './Routes/Routes';
import Loader from './Components/Loader';
import withFirebaseAuth from 'react-with-firebase-auth'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as CitiesActions from './Services/Actions/Cities';
import { providers, firebaseAppAuth } from './Services/FirebaseIntegration';
import NotFound from './Pages/NotFound';
class App extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const get_city = bindActionCreators(CitiesActions.getCity, dispatch)
    get_city();
    if (window.location.host === 'beta-xpatris.web.app' || window.location.host === 'beta-xpatris.firebaseapp.com') {
      window.location.href = 'https://xpatris.com';
    }
  }

  render() {
    return (
      < BrowserRouter >
        <>
          <Suspense fallback={<Loader />}>
            <Switch>
              {
                routes.map((route, index) => {
                  return <Route exact={route.exact} path={route.path} render={() => <route.component {...this.props} />} key={index} />
                })
              }
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </>
      </BrowserRouter >
    );
  }

}

const mapStateToProps = (state) => {
  return {
    ...state
  }
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(connect(mapStateToProps)(App));
