export const pageTitle = {
    Accomodation: "Xpatris | Expat Accommodation | House Listings | Expat Properties In Belgium",
    Moving: "Xpatris | Moving Services | Relocation Services | International Relocation Services | Expat",
    FurnitureRental:"Xpatris | Furniture Rental Companies | Home And Office Furniture Rentals",
    HomeServices:"Xpatris |Cleaning And Facility Services | Home Helps For Expats",
    Utilities:"Xpatris | Utilities  Guide For Expats |GAS | Electricity | Water",
    Schools:"Xpatris | Schools In Belgium | International Schools In Belgium",
    FitnessWellnessClub:"Xpatris | Family Health And Wellness For Expats In Belgium",
    InternationHealth:"Xpatris |Expat health Insurance | International Healthcare",
    LocalMutuelle:"Xpatris |National Health insurances In Belgium | National Health Cover",
    OtherInsurance:"Xpatris | Car Insurance | Home Insurance | Customised Insurances For Expats In Belgium",
    TownHalls:"Xpatris | Town halls | City Centers | Expat Registrations | Social Security",
    BankingFinance:"Xpatris | Best Banking Services In Belgium For Expats",
    VisaWorkPermit:"Xpatris | Visa Services | Immigration Services | Social Security",
    MoneyTransfers:"Xpatris | Money Transfers | Remittance services | Currency Exchange Services",
    Coaching:"Xpatris | Expat Orientation | Cross Cultural Trainings | Coaching",
    MobileTvInternet:"Xpatris | Internet | TV | Mobile Sim cards",
    Payroll:"Xpatris | HR And Payroll Services | Taxation Services For Expats",
    Couriers:"Xpatris |International Courier And Mail Services | Supply Chain Management",
    CarRentals:"Xpatris | Leading car Rental Firms | Electric Cars | Electric Scooters",
    JobAgencies:"Xpatris |International Executive Recruitment Firms | Expat Employment Agencies",
    SelfStorages:"Xpatris | Leading Self Storage Facility Providers",
    LanguageCourses:"Xpatris | Language Classes | Cultural Consulting And Language Trainings",
    UsefulLinks:"Xpatris - Making Your Belgium Relocation Hassel -free",
    Aboutus:"Xpatris - Making Your Belgium Relocation Hassel -free  Or  Xpatris - Smooth Expat Relocation, Assured",
    Home:"Xpatris - Smooth Expat Relocation, Assured"
}

export const pageContent ={
    homeContent:"The Only Digital Platform To Compare Trusted Providers And Direct Access To All Products And Services For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    accomodationContent:"The Only Digital Platform To Compare And Access Accommodation Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    movingContent:"The Only Digital Platform To Compare And Access Moving Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    furnitureContent:"The Only Digital Platform To Compare And Access Furniture Rental Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    homeServicesContent:"The Only Digital Platform To Compare And Access Home/Handymen Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    utlitiesContent:"The Only Digital Platform To Compare And Access Utility Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    schoolsContent:" The Only Digital Platform To Compare And Access Schools In Belgium For Expat Children",
    fitnessContent:"The Only Digital Platform To Compare And Access Fitness Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    internationContent:"The Only Digital Platform To Compare And Access International Health Insurances From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    localContent:"The Only Digital Platform To Compare And Access Health Covers From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    otherInsuranceContent:"The Only Digital Platform To Compare And Access Insurance Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    townContent:"Xpatris- The Only Digital Platform To Compare And Access Town halls In Belgium",
    bankingContent:"The Only Digital Platform To Compare And Access Banking Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    visaContent:"The Only Digital Platform To Compare And Access Immigration, VISA And Other Legal Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    moneyContent:"The Only Digital Platform To Compare And Access Remittance Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    coachingContent:"The Only Digital Platform To Compare And Access Coaching & Consulting Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    mobileContent:"The Only Digital Platform To Compare And Access Mobile & Internet Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    payrollContent:"The Only Digital Platform To Compare And Access Payroll & Taxation Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    couriersContent:"The Only Digital Platform To Compare And Access Courier Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    carContent:"The Only Digital Platform To Compare And Access Transportation & Car Hire Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    jobAgenciesContent:"The Only Digital Platform To Compare And Access  Leading Job Agencies For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    selfStorageContent:"The Only Digital Platform To Compare And Access Self Storage Services From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    languageContent:"The Only Digital Platform To Compare And AccessLanguage Courses From Trusted Partners For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    useFulContent:"The Only Digital Platform To Compare Trusted Providers And Direct Access To All Products And Services For Expats In Belgium, HR Professionals And Global Mobility Specialists.",
    aboutContent:"The Only Digital Platform To Compare Trusted Providers And Direct Access To All Products And Services For Expats In Belgium, HR Professionals And Global Mobility Specialists."
}