import Axios from 'axios';
import baseurl from "./Baseurl";
Axios.interceptors.request.use(function (config) {
    if(!(config.url).includes('https')){
        config.headers['X-AuthToken'] = localStorage.getItem('user') ? localStorage.getItem('user') : ''
        config.url = `${baseurl}${config.url}`
        return config;    
    }
}, function (err) {
    return Promise.reject(err);
});
export default Axios;