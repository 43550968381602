import React from 'react';
import { Result, Button } from 'antd';
import {Link,withRouter} from 'react-router-dom';
const NotFound = (props) => {
    return (
        <div>
            <Result
                status="404"
                title="Page Not Found"
                subTitle="Are you looking for https://xpatris.com"
                extra={<Link to="/"><Button type="primary">Checkout Xpatris</Button></Link>}
            />
        </div>
    )
};

export default withRouter(NotFound);