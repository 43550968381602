import { useState } from "react";

const useToggle = (initialState) => {
    const [FLAG_TOGGLE, setToggle] = useState(initialState);
    const handleToggle = () => {
        setToggle(!FLAG_TOGGLE)
    }
    return {
        FLAG_TOGGLE,
        handleToggle
    }
}

export default useToggle;