import {combineReducers} from 'redux';
import cities from './Cites';
import category from './Category';
import provider from './Provider';
import sub_product from './Sub_Product';

const RootReducer = combineReducers({
    cities,
    category,
    provider,
    sub_product
});

export default RootReducer;