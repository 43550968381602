import baseurl from './Baseurl';
import { requestOptionsPOST,requestOptionsGET, requestOptionsDELETE } from './Helper';

function addCities(data) {
    requestOptionsPOST.body = data;
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user')
    return fetch(baseurl + 'cities/add', requestOptionsPOST).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error(error);
    })
}

function addSection(data){
    requestOptionsPOST.body = data;
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user')
    return fetch(baseurl + 'sections/add',requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}

function addCategories(data){
    requestOptionsPOST.body = data;
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user')
    return fetch(baseurl + 'categories/add',requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error);
    })
}

function addProviders(data){
    requestOptionsPOST.body = data;
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user')
    return fetch(baseurl + 'providers',requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error);
    })
}
function addBuddies(data){
    requestOptionsPOST.body = data;
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user')
    return fetch(baseurl + 'buddies',requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error);
    })
}
function addProducts(data){
    requestOptionsPOST.body = data;
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user')
    return fetch(baseurl + 'products',requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error);
    })
}
function getCityList() {
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + 'cities/public/list', requestOptionsGET).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error(error)
    })
}

function getCityByname(name){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + 'cities/public/' + name, requestOptionsGET).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error(error)
    })
}

function getSectionList(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `sections`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
function getCategoryList(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `categories`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
function getProductList(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
function getBuddyList(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `buddies`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
function getProvidersList(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `providers`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
function getCitiesbyName(citiName) {
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `cities/${citiName}`, requestOptionsGET).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error(error);
    })
}

function getCategoryName(categoryName) {
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `categories/public/${categoryName}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}
function getCategoryNameBYCity(cityname,categoryName) {
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/public/cities/${cityname}/categories/${categoryName}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}
function getDataByProvider(providerName){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/providers/${providerName}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}

function deleteProducts(productName){
    requestOptionsDELETE.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/${productName}`,requestOptionsDELETE).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}

function getProducts(productName){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/public/${productName}`,requestOptionsGET).then((response)=>{
            return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
function getProductsAuthenticate(productName){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `products/${productName}`,requestOptionsGET).then((response)=>{
            return response.json();
    }).catch((error)=>{
        console.error(error);
    })
}
export const CitiesServices = {
    addCities,
    getCityList,
    getCitiesbyName,
    getCategoryName,
    getProducts,
    getProductsAuthenticate,
    addSection,
    addCategories,
    addProviders,
    addProducts,
    getSectionList,
    getCategoryList,
    getProductList,
    getProvidersList,
    getDataByProvider,
    deleteProducts,
    getBuddyList,
    addBuddies,
    getCityByname,
    getCategoryNameBYCity
}
