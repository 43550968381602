import React from 'react';
import { Icon, Spin } from 'antd';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const Loader = props => {
    return (
        <div style={{position:'absolute',left:0,right:0,top:0, display: 'flex', justifyContent: 'center',height:'-webkit-fill-available',alignItems:'center' }}>
            <Spin indicator={antIcon} />
        </div>
    );
};

Loader.propTypes = {

};

export default Loader;