import * as ProviderActions from '../ActionTypes/ActionTypes';
const inititalState = {
    provider: []
}

export default function provider(state=inititalState, action) {
    switch (action.type) {
        case ProviderActions.REQUEST_PROVIDER:
            return { ...state };

        case ProviderActions.RECIEVED_PROVIDER:
            return Array.from(action.provider);
            
        default:
            return state
    }
}