import Axios from "./Axios";
import axios from "axios";
import { pageTitle, pageContent } from "./Constants";
export const userSignin = (firebase_token) => {
    return Axios({
        method: 'post',
        url: '',
        data: firebase_token,
    })
}

export const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 2; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
export const getAlternativeNumber = (index) => {
    let randomClass = ''
    if (index % 2 == 0) {
        randomClass = 'service-container-row-reverse'
    }
    else {
        randomClass = ''
    }
    return randomClass
}

export const randomWholeNum = () => {
    return Math.random() * 20;
}

export const getUserClaims = () => {
    return axios({
        method: 'GET',
        url: `accept-terms`
    })
}

export const updateAccessToken = () => {
    return axios({
        method: 'POST',
        url: `https://securetoken.googleapis.com/v1/${localStorage.getItem("user")}`,
    })
}
export const requestOptionsPOST = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'X-AuthToken': localStorage.getItem('user')
    },
    body: null
};

export const requestOptionsPUT = {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'X-AuthToken': localStorage.getItem('user')
    },
    body: null
}

export const requestOptionsGET = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'X-AuthToken': localStorage.getItem('user')
    }
}
export const requestOptionsDELETE = {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        'X-AuthToken': localStorage.getItem('user')
    }
}

export const getPageTitle = (title) => {
    let url = "/services/"
    switch (title) {
        case url + 'ACCOMMODATION':
            document.title = pageTitle.Accomodation;
            document.getElementsByTagName("META")[4].content=pageContent.accomodationContent
            break;
        case url + 'MOVING':
            document.title = pageTitle.Moving;
            document.getElementsByTagName("META")[4].content=pageContent.movingContent
            break;
        case url + 'FURNITURE-RENTAL':
            document.title = pageTitle.FurnitureRental;
            document.getElementsByTagName("META")[4].content=pageContent.furnitureContent

            break;
        case url + 'HOME-SERVICES':
            document.title = pageTitle.HomeServices;
            document.getElementsByTagName("META")[4].content=pageContent.homeServicesContent

            break;

        case url + 'UTILITIES':
            document.title = pageTitle.Utilities;
            document.getElementsByTagName("META")[4].content=pageContent.utlitiesContent

            break;
        case url + 'SCHOOLS':
            document.title = pageTitle.Schools;
            document.getElementsByTagName("META")[4].content=pageContent.schoolsContent

            break;
        case url + 'FITNESS-WELLNESS-CLUBS':
            document.title = pageTitle.FitnessWellnessClub;
            document.getElementsByTagName("META")[4].content=pageContent.fitnessContent

            break;
        case url + 'INTERNATIONAL-HEALTH-INSURANCE':
            document.title = pageTitle.InternationHealth;
            document.getElementsByTagName("META")[4].content=pageContent.internationContent

            break;
        case url + 'LOCAL-MUTUELLE':
            document.title = pageTitle.LocalMutuelle;
            document.getElementsByTagName("META")[4].content=pageContent.localContent

            break;
        case url + 'OTHER-INSURANCE':
            document.title = pageTitle.OtherInsurance;
            document.getElementsByTagName("META")[4].content=pageContent.otherInsuranceContent

            break;
        case url + 'TOWN-HALLS':
            document.title = pageTitle.TownHalls;
            document.getElementsByTagName("META")[4].content=pageContent.townContent

            break;
        case url + 'BANKING-FINANCE':
            document.title = pageTitle.BankingFinance;
            document.getElementsByTagName("META")[4].content=pageContent.bankingContent

            break;
        case url + 'VISA-WORK-PERMIT':
            document.title = pageTitle.VisaWorkPermit;
            document.getElementsByTagName("META")[4].content=pageContent.visaContent

            break;
        case url + 'MONEY-TRANSFERS':
            document.title = pageTitle.MoneyTransfers;
            document.getElementsByTagName("META")[4].content=pageContent.moneyContent

            break;
        case url + 'COACHING':
            document.title = pageTitle.Coaching;
            document.getElementsByTagName("META")[4].content=pageContent.coachingContent

            break;
        case url + 'MOBILE-TV-INTERNET':
            document.title = pageTitle.MobileTvInternet;
            document.getElementsByTagName("META")[4].content=pageContent.mobileContent

            break;
        case url + 'PAYROLL':
            document.title = pageTitle.Payroll;
            document.getElementsByTagName("META")[4].content=pageContent.payrollContent

            break;
        case url + 'COURIERS':
            document.title = pageTitle.Couriers;
            document.getElementsByTagName("META")[4].content=pageContent.couriersContent

            break;
        case url + 'CAR-RENTALS':
            document.title = pageTitle.CarRentals;
            document.getElementsByTagName("META")[4].content=pageContent.carContent

            break;
        case url + 'JOB-AGENCIES':
            document.title = pageTitle.JobAgencies;
            document.getElementsByTagName("META")[4].content=pageContent.jobAgenciesContent

            break;
        case url + 'SELF-STORAGES':
            document.title = pageTitle.SelfStorages;
            document.getElementsByTagName("META")[4].content=pageContent.selfStorageContent

            break;
        case url + 'LANGUAGE-COURSES':
            document.title = pageTitle.LanguageCourses;
            document.getElementsByTagName("META")[4].content=pageContent.languageContent

            break;
        case url + 'USEFUL-LINKS':
            document.title = pageTitle.UsefulLinks;
            document.getElementsByTagName("META")[4].content=pageContent.useFulContent

            break;
        case '/aboutus':
            document.title = pageTitle.Aboutus;
            document.getElementsByTagName("META")[4].content=pageContent.aboutContent

            break;
        case 'home':
            document.title = pageTitle.Home
            document.getElementsByTagName("META")[4].content=pageContent.homeContent
            break;
        default:
            document.title = pageTitle.Home;
            document.getElementsByTagName("META")[4].content=pageContent.homeContent

            break;
    }
}
export const getParams = (url) => {
    var newUrl = url;
    var vars = newUrl.split('/');
    if(vars[6] != undefined ){
        localStorage.setItem('currentCity',vars[6])
    }
   
};