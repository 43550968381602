import * as SubProductActions from '../ActionTypes/ActionTypes';
const inititalState = {
    sub_product: []
}

export default function subproduct(state=inititalState, action) {
    switch (action.type) {
        case SubProductActions.REQUEST_SUB_PRODUCT:
            return { ...state };

        case SubProductActions.RECIEVED_SUB_PRODUCT:
            return Array.from(action.city);
            
        default:
            return state
    }
}