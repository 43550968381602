// Cities
export const REQUSET_CITES = 'REQUSET_CITES';
export const RECIEVED_CITES = 'RECIEVED_CITES';

// Categories
export const REQUSET_CATEGORIES = "REQUEST_CATEGORIES";
export const RECIEVED_CATEGORIES = "RECIEVED_CATEGORIES";

// Provider
export const REQUEST_PROVIDER = "REQUEST_PROVIDER";
export const RECIEVED_PROVIDER = "RECIEVED_PROVIDER";

// Sub-Product
export const REQUEST_SUB_PRODUCT = "REQUEST_SUB_PRODUCT";
export const RECIEVED_SUB_PRODUCT = "RECIEVED_SUB_PRODUCT";
