import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Select, AutoComplete } from 'antd';
import useToggle from '../Container/useToggle';
import SiderMenu from "./SiderMenu";
import STDModal from './STDModal';
import { Link, withRouter } from "react-router-dom";
import flag from '../Assets/Images/flag.svg';
import { CitiesServices } from '../Services/cities';
import logo from '../Assets/org_logo.png'
import './Header.css'
const { Option } = Select
const Header = props => {
    const { match,header_description, header_description_link, customClass_header_description, header_description_link_href, city_name, flag_img, description_hide, header_service_name } = props;
    const Toggle = useToggle(false);
    const STDToggle = useToggle(false);
    const [listOfCity,setListOfCity] = useState()
    const [listItem,setListItems] = useState()
    function clickBuddyCommunity() {
        if(props.location.pathname =='/buddy-community' || 
           props.location.pathname ==='/become-buddy' ||
           props.location.pathname ==='/ask-me-communtiy' ||
           props.location.pathname ==='/ourbuddies' ||
           props.location.pathname === '/signin/email' ||
           props.location.pathname === '/signup' ||
           props.location.pathname === '/signin' ||
           props.location.pathname === '/faq' ||
           props.location.pathname === '/signup/email' ||
           props.location.pathname === '/profile'){

        }
        else{
            if(header_description_link ==='' || header_description_link[2].includes('compare')){
                props.history.push({
                    pathname: '/ask-me-communtiy',
                    state: {
                        name: header_service_name,
                        pathname: '/ask-me-communtiy',
                    }
                })
            }
            else if(props.cities.name==='Brussels'){
                props.history.push({
                    pathname: '/citytour',
                    state: {
                        name: header_service_name,
                        pathname: '/citytour',
                    }
                })
            }
            else if(props.cities.name==='Leuven'){
                props.history.push({
                    pathname: '/citytour-leuven',
                    state: {
                        name: header_service_name,
                        pathname: '/citytour-leuven',
                    }
                })
            }
            else if(props.cities.name==='Ghent'){
                props.history.push({
                    pathname: '/citytour-ghent',
                    state: {
                        name: header_service_name,
                        pathname: '/citytour-ghent',
                    }
                })
            }
            else if(props.cities.name==='Antwerp'){
                props.history.push({
                    pathname: '/citytour-antwerp',
                    state: {
                        name: header_service_name,
                        pathname: '/citytour-antwerp',
                    }
                })
            }
            else
                {
                props.history.push({
                    pathname: '/buddy-community',
                    state: {
                        name: header_service_name,
                        pathname: '/buddy-community',
                    }
                })
            }
        }

    }
    useEffect(()=>{
        getListOfCity()
        
    })
    const getListOfCity =()=>{
        setTimeout(() => {
            let list = JSON.parse(localStorage.getItem('citylist'))
            let item = JSON.parse(localStorage.getItem('list'))
            setListOfCity(list)
            setListItems(item)
        }, 1000);
    } 
    function searchChange(value) {
        if (props.location.pathname === '/services/'+value) {
            props.history.push({
                pathname: '/services/'+value,
                state: value,
            });
            window.location.reload()
        }
        else {
            props.history.push({
                pathname: '/services/'+value,
                state: value,
            })
            window.location.reload()
        }
    }

    function handleChange(value) {
        localStorage.setItem('currentCity',value)
        if(props.location.pathname.includes('services')){
            props.history.push({
            pathname: '/services/' + localStorage.getItem('currentCategory') + '/' + value,
        })
        }
        else{
            props.history.push({
            pathname: '/',
        })
        }
        window.location.reload()
    }

    return (

        <div className="app-header">
            <div className="app-header-links-menu">
                <div className="app-header-links-menu-logo">
                    <span className="hamberger"><Icon type="menu" onClick={Toggle.handleToggle} /></span>
                    <span className="logo"><span className="logo-icon">
                    </span><Link className="logo-position" to='/' style={{ color: '#fff' }}>
                            <img className="main-logo" alt="Xpatris" src={logo} />
                        </Link></span>
                </div>
                <div className="app-header-links-menu-help-text">
                    <span>
                        <Icon type="search" className="certain-category-icon" style={{ color: '#fff' }} />
                        <Select
                            showSearch onChange={searchChange} className="search-box-help" style={{ width: 200 }} placeholder="How can we help you?" >
                            {listItem ? listItem.map((item, index) => {
                                return <Option key={index} value={item.name}>{item.title} </Option>
                            }) : null
                            }

                        </Select>
                    </span>
                    <span>
                            <Select className="city-select-box" style={{width:100}} size="small" value={props.cities.name} onChange={handleChange}>
                            {listOfCity ? listOfCity.map((item,index)=>{
                            return <Option key={index} value={item.name}>{item.title}</Option>
                        }):null}
                            </Select><img src={flag_img ? flag_img : flag} alt="flag" className="header-button-flag" />
                    </span>
                </div>
            </div>
            <div onClick={clickBuddyCommunity}>
                <div className="app-header-description" style={{ display: description_hide ? 'none' : '' }}>
                    <span className={`app-header-description-text ${customClass_header_description}`}>{header_description} {props.location.pathname.includes('services') ? <span style={{fontWeight:'bold'}}>Ask our buddies</span>:''}</span>
                    {header_description ? <br /> : ''}
                    {header_description_link_href ? <div className={`app-header-description-link`} className={header_description == '' ? 'right-header' : 'left-header'}>
                        {header_description_link == 'City tour!' ? <i className="fa fa-map-marker city-marker"></i> : ''}
                        {header_description_link}
                    </div> : ''}

                </div>
            </div>
            <SiderMenu
                {...props}
                FLAG_VISIBLE={Toggle.FLAG_TOGGLE}
                handleToggle={Toggle.handleToggle}
                city={props.cities.name}
            />
            <STDModal
                FLAG_VISIBLE={STDToggle.FLAG_TOGGLE}
                handleToggle={STDToggle.handleToggle}
            />
        </div>
    );
};

Header.propTypes = {
    header_description: PropTypes.string,
    header_description_link: PropTypes.string,
    customClass_header_description: PropTypes.string,
    header_description_link_href: PropTypes.string,
    city_name: PropTypes.string,
    flag_img: PropTypes.string,
    description_hide: PropTypes.bool
};

export default withRouter(Header);
