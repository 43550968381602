import { lazy } from 'react';
import EmailSignIn from '../Pages/EmailSignIn';
import EmailLogin from '../Pages/EmailLogin';

const Home = lazy(() => import('../Pages/Home'));
const Services = lazy(() => import('../Pages/Services'));
const ServiceDetail = lazy(() => import('../Pages/ServiceDetail'));
const AskBuddy = lazy(() => import('../Pages/AskBuddy'));
const Sucess = lazy(() => import('../Pages/Sucess'));
const Signin = lazy(() => import('../Pages/Signin'));
const Signup = lazy(() => import('../Pages/Signup'));
const Aboutus = lazy(() => import('../Pages/Aboutus'));
const CheckList = lazy(() => import('../Pages/Checklist'));
const CityTour = lazy(() => import('../Pages/CityTour'));
const CityTourLeuven = lazy(() => import('../Pages/CityTourLeuven'));
const CityTourGhent = lazy(() => import('../Pages/CityTourGhent'));
const CityTourAntwerp = lazy(() => import('../Pages/CityTourAntwerp'));
const AddCities = lazy(()=>import('../Pages/AddCities'))
const TermsandCondition = lazy(()=> import('../Pages/Terms'));
const Privacypolicy = lazy(()=> import('../Pages/Privacy'));
const ContactUsForm = lazy(()=> import('../Pages/ContactForm'));
const AddSection = lazy(()=>import('../Pages/AddSection'));
const AddCategories = lazy(()=>import('../Pages/AddCategories'));
const AddProvider = lazy(()=>import('../Pages/AddProvider'));
const AddProducts = lazy(()=>import('../Pages/AddProducts'));
const AddSubProducts = lazy(()=>import('../Pages/AddSubproducts'));
const AdminPortal = lazy(()=>import('../Pages/Admin'));
const OurBuddies =  lazy(()=>import('../Pages/OurBuddies'));
const BuddyProfile =  lazy(()=>import('../Pages/BuddyProfile'));
const Faq =  lazy(()=>import('../Pages/Faq'));
const NotFound =  lazy(()=>import('../Pages/NotFound'));
const CustomEmailverification =  lazy(()=>import('../Pages/Emailverify'));
const ProductReviews = lazy(()=>import('../Pages/ProductReviews'));
const Profile = lazy(()=>import('../Pages/Profile'));
export const routes = [
    { path: '/', exact: true, component: Home },
    { path: '/buddy-community', exact: true, component: AskBuddy },
    { path: '/become-buddy', exact: true, component: AskBuddy },
    { path: '/ask-me-communtiy', exact: true, component: AskBuddy },
    { path: '/services/:category/:city',exact: true, component: Services },
    { path: '/services/service-details/:productname/:city',exact: true,  component: ServiceDetail },
    { path: '/sucess', exact: true, component: Sucess },
    { path: '/signup', exact: true, component: Signup },
    { path: '/signin', exact: true, component: Signin },
    { path: '/signup/email', exact:true, component:EmailSignIn},
    { path: '/signin/email', exact:true,component:EmailLogin},
    { path: '/aboutus', exact: true, component: Aboutus },
    { path: '/checklist', exact: true, component: CheckList },
    { path: '/citytour', exact: true, component: CityTour },
    { path: '/citytour-leuven', exact: true, component: CityTourLeuven },
    { path: '/citytour-ghent', exact: true, component: CityTourGhent },
    { path: '/citytour-antwerp', exact: true, component: CityTourAntwerp },
    { path: '/admin-cities', exact: true, component: AddCities },
    { path: '/termsandcondition', exact: true, component: TermsandCondition },
    { path: '/privacypolicy',exact : true, component:Privacypolicy},
    { path: '/contactus',exact : true, component:ContactUsForm},
    { path: '/admin-section',exact : true, component:AddSection},
    { path: '/admin-categories',exact : true, component:AddCategories},
    { path: '/admin-providers',exact : true, component:AddProvider},
    { path: '/admin-products',exact : true, component:AddProducts},
    { path: '/addsubProdcuts',exact : true, component:AddSubProducts},
    { path:'/ourbuddies',exact:true,component:OurBuddies},
    { path:'/buddies-profile',component:BuddyProfile},
    { path: '/adminportal', component:AdminPortal},
    { path: '/faq', component:Faq},
    { path: '/emailVerification', component:CustomEmailverification},
    { path:'/reviews',component:ProductReviews},
    { path:'/profile',component:Profile},
    // { path:'*',component:NotFound}
]




