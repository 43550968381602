import baseurl from './Baseurl';
import {requestOptionsPOST,requestOptionsGET, requestOptionsPUT,requestOptionsDELETE} from './Helper';

function rateProduct(data){
    requestOptionsPOST.body = JSON.stringify(data);
    requestOptionsPOST.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/rate`,requestOptionsPOST).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}

function retriveProductReviews(productName){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/reviews/${productName}`,requestOptionsGET).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}

function sendEnquiry(data){
    requestOptionsPOST.body = JSON.stringify(data);
    requestOptionsPOST.headers["X-AuthToken"]= localStorage.getItem('user');
    return fetch(baseurl + 'forms',requestOptionsPOST).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}

function getProductByName(productName){
    requestOptionsGET.headers["X-AuthToken"]= localStorage.getItem('user');
    return fetch(baseurl + `products/get/${productName}`,requestOptionsGET).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}

function updateProduct(data){
    requestOptionsPUT.headers["X-AuthToken"]= localStorage.getItem('user');
    requestOptionsPUT.body = data
    return fetch(baseurl + `products/update`,requestOptionsPUT).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}
function deleteBuddies(buddyName){
    requestOptionsDELETE.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `buddies/${buddyName}`,requestOptionsDELETE).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}
function deleteProvider(provider_name){
    requestOptionsDELETE.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `providers/${provider_name}`,requestOptionsDELETE).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}

function getCategories(name){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `categories/get/${name}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function updateCategories(data){
    requestOptionsPUT.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPUT.body = data
    return fetch(baseurl + `categories/update`,requestOptionsPUT).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function bookMark(productName,data){
    requestOptionsPOST.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPOST.body = data
    return fetch(baseurl + `products/bookmark/${productName}`,requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function bookMarkUpdate(data){
    requestOptionsPUT.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPUT.body = data
    return fetch(baseurl + `products/bookmark/update`,requestOptionsPUT).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function bookMarkList(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `user/bookmarks`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function unBookMark(id){
    requestOptionsDELETE.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `products/bookmark/${id}`,requestOptionsDELETE).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}

function complete(id){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `bookmarks/mark/complete/${id}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function inComplete(id){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `bookmarks/mark/incomplete/${id}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function getBuddyByName(name){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `buddies/${name}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}

function editBuddyByName(name){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `buddies/get/${name}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function getProductByCategory(name){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `products/categories/${name}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function updateBuddy(data){
    requestOptionsPUT.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPUT.body = data
    return fetch(baseurl + `buddies`,requestOptionsPUT).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}

function getPublicCategory(){
    return fetch(baseurl + `categories/public/list`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function alertProduct(productName){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `products/tmt/${productName}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function dashboard(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `dashboards`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function deleteReviewsProduct(buddyName){
    requestOptionsDELETE.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `products/reviews/${buddyName}`,requestOptionsDELETE).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}
function getAllUsers(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `users`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function getAllBookmarks(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `products/bookmarks`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function getAllTakeMeThere(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `products/tmts`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function updateUserBookmarks(data){
    requestOptionsPOST.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPOST.body = data
    return fetch(baseurl + `users/bookmark`,requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function getBuddiesByCategory(category,name){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user');
    return fetch(baseurl + `buddies/by/${category}/${name}`,requestOptionsGET).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}

function updateProfile(){
    requestOptionsPUT.headers["X-AuthToken"]= localStorage.getItem('user');
    requestOptionsPUT.body = []
    return fetch(baseurl + `user/profile`,requestOptionsPUT).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}
function deleteProfile(){
    requestOptionsDELETE.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `user`,requestOptionsDELETE).then((response)=>{
        return response.json();
    }).catch((error)=>{
            console.error(error)
    })
}

function logoutapi(){
    requestOptionsGET.headers["X-AuthToken"] = localStorage.getItem('user')
    return fetch(baseurl + `user/logout`,requestOptionsGET).then((response)=>{
        return response.json()
    }).catch((error)=>{
        console.error(error)
    })
}
function reactivateAccount(data){
    requestOptionsPOST.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPOST.body = data
    return fetch(baseurl + `user/reactivate`,requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function reactivateUserAccount(user){
    requestOptionsPOST.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPOST.body = user
    return fetch(baseurl + `admin/reactivate/user`,requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
function deactivateUserAccount(user){
    requestOptionsPOST.headers["X-AuthToken"] = localStorage.getItem('user');
    requestOptionsPOST.body = user
    return fetch(baseurl + `admin/deactivate/user`,requestOptionsPOST).then((response)=>{
        return response.json();
    }).catch((error)=>{
        console.log(error)
    })
}
export const productServices ={
    reactivateUserAccount,
    deactivateUserAccount,
    getBuddiesByCategory,
    updateUserBookmarks,
    getAllTakeMeThere,
    getAllBookmarks,
    getAllUsers,
    deleteReviewsProduct,
    rateProduct,
    retriveProductReviews,
    sendEnquiry,
    getProductByName,
    updateProduct,
    deleteBuddies,
    getCategories,
    updateCategories,
    bookMark,
    bookMarkList,
    unBookMark,
    complete,
    inComplete,
    getBuddyByName,
    editBuddyByName,
    updateBuddy,
    getProductByCategory,
    getPublicCategory,
    alertProduct,
    deleteProvider,
    dashboard,
    bookMarkUpdate,
    updateProfile,
    deleteProfile,
    logoutapi,
    reactivateAccount
    
}