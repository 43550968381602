import * as CatrgoryActions from '../ActionTypes/ActionTypes';
const inititalState = {
    category: []
}

export default function category(state=inititalState, action) {
    switch (action.type) {
        case CatrgoryActions.REQUSET_CATEGORIES:
            return { ...state };

        case CatrgoryActions.RECIEVED_CATEGORIES:
            return Array.from(action.categories);
            
        default:
            return state
    }
}