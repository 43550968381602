import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Select ,Icon} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { CitiesServices } from '../Services/cities';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
const { Option } = Select;

const STDModal = props => {
    const { FLAG_VISIBLE, handleToggle, history } = props;
    const [list, setList] = useState()
    useEffect(() => {
        // getList()
    }, [])
    const handleClick = () => {
        handleToggle();
        history.push({
            pathname: '/'
        })
        window.location.reload()
    }

    function handleChange(value) {
        localStorage.setItem('currentCity',value)
    }
    function getList() {
        CitiesServices.getCityList().then((response) => {
            if (response.status === 200) {
                setList(response.body)
            }
        }).catch((error) => { console.error(error) });
    }

    return (
        <Modal
            visible={FLAG_VISIBLE}
            footer={null}
            closable={true}
            onCancel={handleToggle}
            className="std-modal"
        >
            <Form {...formItemLayout} id="STD-Modal">
                <Form.Item hasFeedback validateStatus="">
                    {/* <Input
                        prefix={<FontAwesomeIcon icon={faPlaneDeparture} />}
                        style={{ width: '100%',color:'#000' }}
                        placeholder="Which city are you going to?"
                    /> */}
                    <Select size="large" prefix={<Icon type="mail" />} placeholder="Going to" className="select-subject"  onChange={handleChange}>
                        {list ? list.map((item,index)=>{
                            return <Option disabled={index != 1 && index != 3} key={index} value={item.name}>{item.title}</Option>
                        }):null}
                        {/* <Option  value={list ? list[1].name:''}>{list?list[1].title:''}</Option> */}
                        
                    </Select>
                </Form.Item>
                {/* <Form.Item hasFeedback validateStatus="">
                    <Input
                        prefix={<FontAwesomeIcon icon={faPlaneArrival} />}
                        style={{ width: '100%', color: '#000' }}
                        defaultValue="Brussels"
                    />
                </Form.Item> */}
                <Button className="std-modal-action-button lets-button" onClick={handleClick}> Let's start your journey! </Button>
            </Form>
        </Modal>
    );
};

STDModal.propTypes = {
    FLAG_VISIBLE: PropTypes.bool,
    handleToggle: PropTypes.func
};

export default withRouter(STDModal);
