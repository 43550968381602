import * as CityActions from '../ActionTypes/ActionTypes';
import Axios from '../Axios';
import { getParams } from '../Helper';

export function requestCity() {
  return {
    type: CityActions.REQUSET_CITES
  }
}

export function receiveCity(responseData) {
  return {
    type: CityActions.RECIEVED_CITES,
    city: responseData
  }
}

export function getCity() {
  getParams(window.location.href)
  let currentCity = localStorage.getItem('currentCity');
  let cityName;
  if (currentCity != null) {
    cityName = currentCity
  }
  else  if(currentCity == 'undefined'){
    cityName = "Brussels"
  }
  else{
    cityName = "Brussels"
  }
  return function (dispatch) {
    dispatch(requestCity());
    return Axios.get(`cities/public/` + cityName)
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch(receiveCity(response.data.body));
          getCategogries(response.data.body.sections)
        }

      })
      .catch(function (error) {
      });
  };
}

function getCategogries(data) {
  let title = '';
  let name = ''
  let categories = [];
  let categoriesName = []
  for (let index in data) {
    for (let childIndex in data[index].categories) {
      title = data[index].categories[childIndex].title
      categories.push(title);
    }
  }
  for (let index in data) {
    for (let childIndex in data[index].categories) {
      name = data[index].categories[childIndex].name
      categoriesName.push(name);
    }
  }
  categories.sort(function (a, b) {
    if (a < b) {
      return -1
    }
    else {
      return 1
    }
  })
}