import React, { useState, useEffect } from 'react';
import { Button, Row, Col, message, Icon, Form, Input, Modal } from 'antd';
import { firebaseAppAuth } from "../Services/FirebaseIntegration";
import { Link, withRouter } from "react-router-dom";
import { getUserClaims } from '../Services/Helper';
import Header from '../Components/Header';
import { productServices } from '../Services/product';
const { confirm } = Modal;

const EmailLogin = (props) => {
  const { history, user, location, signOut } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [errMessage, setErrMessage] = useState();
  const [successMessage, setSuccessMessage] = useState()
  const [errorFlag, setErrorFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false)
  const [activating,setactivating] =useState(false)
  const [termsContent,settermsContent] = useState(['By signing in we assume you accept xpatris.com’s terms and conditions. Do not continue to use xpatris.com if you do not agree to take all of the terms and conditions stated on', <a href ='https://xpatris.com/termsandcondition' target='_blank' style={{color:'#fff'}}> https://xpatris.com/termsandcondition</a>])
  const handleLogout = () => {
    signOut();
    localStorage.removeItem('isLogin');
    localStorage.removeItem('user');
    history.push({
      pathname: '/'
    });
  };

  function showConfirm(idTokenResult) {
    confirm({
      title: 'Terms and Conditions',
      content: termsContent,
      okText: "Accept",
      onOk() {

        getUserClaims().then((res) => {
          if (res && localStorage.getItem('user')) {
            firebaseAppAuth.currentUser.getIdToken(true).then(function (response) {
              localStorage.setItem('user', response);
              setTimeout(() => {
                firebaseAppAuth.currentUser.getIdTokenResult()
                  .then((idTokenResult) => {
                    if (!!idTokenResult.claims.isAccepted) {
                      localStorage.setItem('ROLES', JSON.stringify(idTokenResult.claims.roles))
                      location.state ?
                        history.push({
                          pathname: location.state.path,
                          state: location.state.location.state
                        })
                        :
                        history.push('/')
                    } else {
                      location.state ?
                        history.push({
                          pathname: location.state.path,
                          state: location.state.location.state
                        })
                        :
                        console.log('accept popup')
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                    handleLogout();
                  });
              }, 500)
            });
          }
          location.state ?
            history.push({
              pathname: location.state.path,
              state: location.state.location.state
            })
            :
            history.push('/')
        }).catch((err) => {
          console.error(err)
          handleLogout();
        })
      },
      onCancel() { handleLogout() },
    });
  }
  const handleSubmit = (e) => {
    setLoader(true)
    e.preventDefault()
    firebaseAppAuth.signInWithEmailAndPassword(email, password)
      .then((response) => {
        setLoader(false)
        if (response.user.emailVerified == true) {
          if (user) {
            firebaseAppAuth.currentUser.getIdTokenResult().then((idTokenResult) => {
              if (!!idTokenResult.claims.isAccepted) {
                localStorage.setItem("isLogin", true);
                localStorage.setItem("user", user._lat)
                localStorage.setItem('ROLES', JSON.stringify(idTokenResult.claims.roles))
                location.state ?
                  history.push({
                    pathname: location.state.path,
                    state: location.state.location.state
                  })
                  :
                  history.push('/')
              } else {
                console.log('not ok')
                localStorage.setItem("isLogin", true);
                localStorage.setItem("user", user._lat)
                location.state ?
                  history.push({
                    pathname: location.state.path,
                    state: location.state.location.state
                  })
                  :
                  showConfirm(idTokenResult);
              }
            }).catch((error) => {
              setLoader(false)
              console.log(error);
              handleLogout();
            });
          }
        }
        else {
          setLoader(false)
          message.error('User Email is Not verfied, Please verify and Login')
        }
      })
      .catch(function (error) {
        setLoader(false)
        if (error.code === 'auth/user-disabled') {
          setVisible(true)
        }
        else {
          message.error(error.message)
          setVisible(false)
        }

      })
  }


  const handleChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value)
  }
  const handleChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value)
  }

  useEffect(() => {
    if (user) {
      if (user.emailVerified === true && localStorage.getItem('user')) {
        firebaseAppAuth.currentUser.getIdTokenResult().then((idTokenResult) => {
          if (!!idTokenResult.claims.isAccepted) {
            localStorage.setItem("isLogin", true);
            localStorage.setItem("user", user._lat)
            localStorage.setItem('ROLES', JSON.stringify(idTokenResult.claims.roles))
            location.state ?
              history.push({
                pathname: location.state.path,
                state: location.state.location.state
              })
              :
              history.push('/')
          } else {
            localStorage.setItem("isLogin", true);
            localStorage.setItem("user", user._lat)
            location.state ?
              history.push({
                pathname: location.state.path,
                state: location.state.location.state
              })
              :
              console.log('popUp')
          }
        }).catch((error) => {
          console.log(error);
          handleLogout();
        });
      }
    }
  })

  const forgotPassword = () => {
    var auth = firebaseAppAuth;
    var emailAddress = email;
    if (email === undefined || email === '') {
      setErrorFlag(true)
    }
    auth.sendPasswordResetEmail(emailAddress).then((response) => {
      message.success(`Password reset email sent to ${email}`)
      setErrMessage('');
      setErrorFlag(false)
    }).catch(function (error) {
      message.error(error.message)
    });
  }
  const handleCancel = () => {
    setVisible(false)
  }
  function refreshToken() {
    firebaseAppAuth.currentUser.getIdToken(true).then(function (idToken) {
        if (idToken) {
            localStorage.setItem('user', idToken);
            setTimeout(() => {
              handleOk()
            })
        }
    }).catch((error) => {
        console.error(error)
    })
}
  const handleOk = () => {
    setactivating(true)
    let data={
      email:email
    }
    let body = JSON.stringify(data);
    productServices.reactivateAccount(body).then((response) => {
      setactivating(false)
      if (response.status === 200) {
        setVisible(false)
      }
      else if (response.status === 401) {
        refreshToken()
      }
    }).catch((error) => {
      setactivating(false)
      message.error(error.message)
    })
  }
  return (
    <div className="app">
      <div className="app-container">
        <Header
          {...props}
          header_description='Login using Email and Password'
          customClass_header_description="sign-up-header-text"
        />
        <Row style={{ margin: '3em' }}>
          <Col xs={{ span: 24 }} md={{ span: 8, offset: 8 }}>
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item style={{ marginBottom: '1em' }}>
                <Input
                  className={errorFlag ? "error-border" : ''}
                  size="large"
                  name="email" value={email} onChange={handleChangeEmail}
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
                {errorFlag ? <span style={{ color: 'red' }}>Email required</span> : ''}
              </Form.Item>
              <Form.Item style={{ marginBottom: '1em' }}>
                <Input name="password" size="large" value={password} onChange={handleChange}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: '1em' }}>
                <Button block className="save-btn" size="large" htmlType="submit" loading={loader}>Sign In</Button>
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>{errMessage}</span>
                <span style={{ color: 'green' }}>{successMessage}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: '1em' }}>
                <Link to="/signup/email"><span>Go to Sign up</span> </Link>
                <span style={{ float: 'right', cursor: 'pointer', color: '#096dd9' }} onClick={forgotPassword}>Forgot Password</span>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <Modal
        className="displayNameModal"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <div className="row">
          <div className="col-md-12 react-modal">
           <span>Please contact <span style={{color:'#1890ff',fontWeight:'bold'}}>info@xpatris.com</span> to reactivate your account.</span>
         </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Button type="primary" block ghost onClick={handleCancel}>Close</Button>
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default withRouter(EmailLogin);