import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Avatar } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import { productServices } from '../Services/product';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

const SiderMenu = props => {
    const { FLAG_VISIBLE, handleToggle, history, user, signOut, location,city } = props;
    const [menu, SetMenu] = useState(false)
    const handleLogout = () => {
        signOut();
        logout()
    };

    const handleBecomeBuddy = (value) => {
        history.push({
            pathname: '/become-buddy',
            state: {
                title: 'Become Buddy',
                pathname: '/become-buddy',
                location: location,
                selectedValue: value
            }
        })
    }
    const ourBuddies = () => {
        history.push({
            pathname: '/ourbuddies',
            state: {
                title: 'Our Buddies',
                pathname: '/ourbuddies',
                location: location,
            }
        })
    }
    const checkList = () => {
        history.push({
            pathname: '/checklist',
            state: {
                title: 'CheckList',
                pathname: '/checklist',
                location: location,
                city:city
            }
        })
    }
    const handleAskBuddy = (value) => {
        history.push({
            pathname: '/buddy-community',
            state: {
                title: 'Become Buddy',
                pathname: '/buddy-community',
                location: location,
                selectedValue: value
            }
        })
        localStorage.removeItem('referance')
    }

    useEffect(() => {
        let roles = JSON.parse(localStorage.getItem('ROLES'))
        if (roles ? roles.includes('ROLE_ADMIN') : null) {
            SetMenu(true)
        }
        else {
            SetMenu(false)
        }
    }, [])
    function logout() {
        productServices.logoutapi().then((response) => {
            localStorage.removeItem('isLogin');
            localStorage.removeItem('user');
            localStorage.removeItem('ROLES');
            history.push({
                pathname: '/'
            });
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <div>
            <Drawer
                placement="left"
                closable={false}
                onClose={handleToggle}
                visible={FLAG_VISIBLE}
                id="sidermenu"
            >
                {
                    localStorage.getItem('isLogin')
                        ?
                        <div>
                            <Avatar src={user ? user.photoURL : ''} />
                            <span className="site-avatar-username" >{user ? user.displayName : 'User Name'}</span>
                        </div>
                        :
                        null
                }
                <ul className="site-option-list" style={{ display: !localStorage.getItem('isLogin') ? 'none' : '' }}>
                    <li style={{ display: menu ? 'block' : 'none' }} className='margin-bottom-15 padding-rtl-15'><NavLink to="/adminportal/admin-dashboard" className="menu-link-anchor">Admin</NavLink></li>
                </ul>
                <ul className="site-option-list">
                    <li className='margin-bottom-15 padding-rtl-15'><NavLink to="/" className="menu-link-anchor">Home</NavLink></li>
                    <li style={{ display: !localStorage.getItem('isLogin') ? 'none' : '' }} className='margin-bottom-15 padding-rtl-15'><NavLink to="/profile" className="menu-link-anchor">Profile</NavLink></li>
                    <li style={{ display: !localStorage.getItem('isLogin') ? 'none' : '' }} className='margin-bottom-15 padding-rtl-15'><span onClick={() => checkList()} className="menu-link-anchor" style={{ paddingLeft: '0.85em', color: '#fff', cursor: 'pointer' }}>Your expat checklist</span></li>

                </ul>
                <ul className="site-option-list">
                    <li className='margin-bottom-15 padding-rtl-15' style={{ display: localStorage.getItem('isLogin') ? 'none' : '' }}><NavLink to="/signin" className="menu-link-anchor">Login</NavLink></li>
                    <li className='margin-bottom-15 padding-rtl-15' style={{ display: localStorage.getItem('isLogin') ? 'none' : '' }}><NavLink to="/signup" className="menu-link-anchor">Sign up</NavLink></li>

                    <li className='margin-bottom-15 padding-rtl-15'><span className="menu-link-anchor" onClick={() => handleAskBuddy('')} style={{ paddingLeft: '0.85em', color: '#fff', cursor: 'pointer' }}>Ask our buddy community</span></li>
                    <li className='margin-bottom-15 padding-rtl-15'><span onClick={() => ourBuddies()} className="menu-link-anchor" style={{ paddingLeft: '0.85em', color: '#fff', cursor: 'pointer' }}>Our buddies</span></li>
                    {/* <li className='margin-bottom-15 padding-rtl-15'><span className="menu-link-anchor" onClick={() => handleBecomeBuddy("I want to become a buddy")} style={{ paddingLeft: '0.85em', color: '#fff', cursor: 'pointer' }}>Become a buddy</span></li> */}
                </ul>
                <ul className="site-option-list">
                    <li className='margin-bottom-15 padding-rtl-15'>
                        <NavLink to="/aboutus" className="menu-link-anchor">About us</NavLink>
                        {/* <a href="aboutus.html" target="_blank">About Us</a> */}
                        </li>
                    <li className='margin-bottom-15 padding-rtl-15'><NavLink to="/faq" className="menu-link-anchor">FAQ</NavLink></li>
                    <li className='margin-bottom-15 padding-rtl-15' style={{ display: !localStorage.getItem('isLogin') ? 'none' : '', paddingLeft: '0.85em', color: '#fff', cursor: 'pointer' }} onClick={handleLogout}>Logout</li>
                </ul>
                <div className="centerContent">
                    <div className="selfCenter standardWidth">
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="Xpatris1"
                            options={{ height: 400, width: '100%', tweetLimit: "3", }}
                            noHeader={true}
                            noBorders={true}
                            noFooter={true}
                            transparent={true}
                            linkColor="#fff"
                            slug={"angular"}
                            theme="dark"
                        />
                    </div>
                </div>

            </Drawer>

        </div>
    );
};

SiderMenu.propTypes = {
    FLAG_VISIBLE: PropTypes.bool,
    handleToggle: PropTypes.func
};

export default withRouter(SiderMenu);
